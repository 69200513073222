/* You can add global styles to this file, and also import other style files */
@font-face{
    font-family: 'Exo';
    src: url('./assets/fonts/exo/Exo2-VariableFont_wght.ttf') format("truetype");
}


$colorBlue: #00011f;
$colorRed: #FF3100;
$background: #f3f3f3;
$colorYellow: #FFCC00;


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Exo';
}

html{
    scroll-behavior: smooth ;
}

input,
a,
button,
div{
    -webkit-tap-highlight-color: transparent;
}

.my-container{
    max-width: 1200px;
    padding: 10px 20px;
}



.title{
    font-size: 1.9em;
    font-weight: bold;
    padding: 30px 0 10px 0;
    position: relative;
}

.title::before{
    content: "";
    position: absolute;
    width: 2em;
    height: 3px;
    bottom:0;
    border-bottom: 4px solid $colorYellow;
}
